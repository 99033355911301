<template>
  <v-app>
    <v-app-bar
      app
      dark
    >
      <div class="d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          src="logo.png"
          transition="scale-transition"
          width="200"
        />
      </div>
    </v-app-bar>

    <v-main>
      <router-view /> 
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
