<template>
  <v-row
    justify="center"
    class="mt-5"
  >
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6" 
    >
      <v-card ref="form">
        <v-card-title>
          Create TX Approval Environment
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="valObserver"
          >
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Company Name:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Company Name"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.companyName"
                required
                :error-messages="errors"
                hint="Registered Business or Non-Profit Organization Name"
                persistent-hint
              />
            </validation-provider>
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Environment Name:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Tenant Name"
              rules="required|alpha_num"
            >
              <v-text-field
                outlined
                v-model="company.environment"
                required
                :error-messages="errors"
                hint="Environment name that will be used for logging in"
                persistent-hint
              />
            </validation-provider>

            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Name:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Full Name"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.name"
                required
                :error-messages="errors"
                hint="Your full name"
                persistent-hint
              />
            </validation-provider>

            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Email</span>
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              rules="required|email"
            >
              <v-text-field
                outlined
                v-model="company.email"
                required
                :error-messages="errors"
                hint="Email address"
                persistent-hint
              />
            </validation-provider>
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>PIN:</span>
            <validation-provider
              v-slot="{ errors }"
              name="PIN"
              rules="required|numeric|min:4|max:4"
            >
              <v-text-field
                outlined
                v-model="company.pin"
                required
                :error-messages="errors"
                hint="4-digit number"
                persistent-hint
                type="password"
              />
            </validation-provider>

            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Device Name:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Device Name"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.deviceName"
                required
                :error-messages="errors"
                hint="The name of your mobile device to help you identify it."
                persistent-hint
              />
            </validation-provider>

            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Device ID:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Device ID"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.deviceId"
                required
                :error-messages="errors"
                hint="The unique ID of your device. You can get it by launching the TX Approval app on your device."
                persistent-hint
              />
            </validation-provider>
            <vue-recaptcha
              sitekey="6Lf3EnAdAAAAAKMQFnGd55hWxGJRKZg23Q-8r1_o"
              :load-recaptcha-script="true"
              @verify="validateCap"
            />
          </validation-observer>
        </v-card-text>
        
        <v-divider class="mt-12" />
        <span style="font-weight: bold;">IMPORTANT:</span>
        &nbsp;By submitting this form, you are agreeing with
        <v-btn
          text
          color="primary"
          @click="termsClicked"
        >
          Terms Of Use
        </v-btn> and 
        <v-btn
          text
          color="primary"
          @click="privacyClicked"
        >
          Privacy Policy
        </v-btn> of TX Approval application.
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, email, alpha_num, max, numeric, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import RegistrationApi from '@/api/RegistrationApi.js'
import VueRecaptcha from 'vue-recaptcha';
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})
extend('min', {
  ...min,
  message: '{_field_} must be 4 characters'
})
extend('max', {
  ...max,
  message: '{_field_} must be 4 characters'
})
extend('numeric', {
  ...numeric,
  message: '{_field_} must consist of digits only'
})

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} can not contait special characters'
})
extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha
  },

  data: () => ({
    showPassword: false,
    company: {
      companyName: '',
      environment: '',
      name: '',
      email: '',
      pin: '',
      deviceName: '',
      deviceId: ''
    },
    reCaptcha: false

  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.$route.query.deviceName != null) this.company.deviceName = this.$route.query.deviceName
      if (this.$route.query.deviceId != null) this.company.deviceId = this.$route.query.deviceId
      if (this.$route.query.env != null) this.company.environment = this.$route.query.env
      if (this.$route.query.name != null) this.company.name = this.$route.query.name
      if (this.$route.query.email != null) this.company.email = this.$route.query.email
      if (this.$route.query.pin != null) this.company.pin = this.$route.query.pin
    },
    async validate(){
      return await this.$refs.valObserver.validate()
    },

    async submit(){
      if(!this.validate()) return
      if(!this.reCaptcha) return
      this.company.link = `${this.company.name}.zitaert.com`
      var result = await RegistrationApi.create(this.company)
      if(!result) return
      this.$router.push('/welcome')
      
    },  
    validateCap (response) {
      if(response) this.reCaptcha = true
    },
    privacyClicked(){
      this.$router.push("/privacy-policy");
    },
    termsClicked(){
      this.$router.push("/terms-of-use");
    }
  }
}

</script>