import axios from "axios";

class RegistrationApi{
    async create(data) {
      console.log(data);
        let res;
        const errorText = 'Could not create';
        try {
          res = await axios.post('/tenants', data);
          if (res.status === 200) {
            res = res.data;
          }
          else {
            console.error(errorText);
            res = false;
          }
        } catch (e) { 
          console.error(errorText);
          console.error(e);
          res = false;
        }
        return res;
    }
}
const registrationApi = new RegistrationApi();
export default registrationApi;
